

var woofunc;

(function ($) {

	woofunc = (function () {

		let surfpacTheme = {
			ajaxLinks: '.product-categories a, .widget_product_categories a, .widget_layered_nav_filters a, .woocommerce-widget-layered-nav a, .filters-area:not(.custom-content) a, body.post-type-archive-product:not(.woocommerce-account) .woocommerce-pagination a, body.tax-product_cat:not(.woocommerce-account) .woocommerce-pagination a, .shop-tools a, .woocommerce-layered-nav a, .price-filter a, .clear-filters-wrapp a, .woocommerce-sort-by a',
		}

		// Storage Handling
		try {
			surfpacTheme.supports_html5_storage = ('sessionStorage' in window && window.sessionStorage !== null);
			window.sessionStorage.setItem('surfpacific', 'test');
			window.sessionStorage.removeItem('surfpacific');
		} catch (err) {
			console.log('session storage not available');
			surfpacTheme.supports_html5_storage = false;
		}

		return {

			init: function () {
				// if (!coreData.woo_installed) return;
				this.ajaxFunctionality();
				// this.pjaxFunctions();
				// this.productSearch();
				// this.sortByWidget();
				// this.productSpecificationsPopup();
				// this.mobileFilter();
				$(window).resize();
			},

			notify: function(notifyData) {
				const nd = JSON.parse(notifyData);
				PNotify.success({
					title: nd.title,
					titleTrusted: true,
					text: nd.content,
					textTrusted: true,
					icon: false,
					animate: {
						animate: true,
						in_class: 'slideInDown',
						out_class: 'slideOutUp'
					},
					delay: 3000
				});
			},

			/**
			 *-------------------------------------------------------------------------------------------------------------------------------------------
			 * AJAX Functionality
			 *-------------------------------------------------------------------------------------------------------------------------------------------
			 */

			ajaxFunctionality: function() {
				// add to cart single product
				$(document).on('click', ".add-single-product-to-cart-button", function(e) {
					const $this = $(this);
					$this.attr('disabled', true).css({'cursor': 'wait'});
					e.preventDefault();
					const product = $this.data('product-id');
					const quantity = $this.data('product-quantity');
					$.ajax({
						method: "post",
						url: coreData.ajaxurl,
						data: {
							action: 'add_product_to_cart',
							product: product,
							quantity: quantity,
						},
						success: function(data) {
							if (data.length > 0) {
								$this.attr('disabled', false).css({'cursor': 'pointer'});
								woofunc.notify(data);
							} else {
								$this.removeAttr('disabled').css({'cursor': ''});
							}
						}
					});
				});

			},
		}

	}());

})(jQuery);

jQuery(document).ready(function () {
	woofunc.init();
});
