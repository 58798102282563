/* Blog Post Block JS File */
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';

$(document).ready(function() {

	// if ($(".banner-home-wrapper").length) {

		// const bannerHomeController = new ScrollMagic.Controller();
		// const bannerHomeScene = new ScrollMagic.Scene({
		// 	triggerHook: "onEnter",
		// 	duration: "200%"
		// })
		// .setTween(".banner-home-wrapper .section-background .background", {
		// 	y: "20%"
		// });

		// bannerHomeScene.addTo(bannerHomeController);
});
