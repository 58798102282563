/* Landing Page Intro Block JS File */
$(document).ready(function() {
	if ($(".landing-page-intro-wrapper").length) {
		$(document).on('click', '.landing-video-popup-trigger', function() {
			const video = $(this).attr('data-video');
			if (typeof video !== "undefined") {
				$.magnificPopup.close();
				$.magnificPopup.open({
					type: 'ajax',
					items: {},
					mainClass: 'video-popup fade-in',
					tLoading: coreData.loader,
					ajax: {
						settings: {
							type: "post",
							url: coreData.ajaxurl,
							data: {
								action: "videoPopup",
								video: video
							},
						},
						cursor: 'mfp-ajax-cur',
						tError: '<a href="%url%">The content</a> could not be loaded.'
					},
					callbacks: {
						ajaxContentAdded: function() {

						}
					}
				});
			}
		});
	}
});
