/* Single Product Block JS File */
$(document).ready(function() {
	if ($(".single-product-wrapper").length) {
		// $(".single-product-wrapper .section-product .section-quantity .quantity-arrows .arrow-up").on('click', function() {
		// 	if ($(this).parent().prev().hasClass('quantity')) {
		// 		$(this).parent().prev().find('input')[0].stepUp(1);
		// 		$(".single-product-wrapper .section-product .product-cart").attr('data-product-quantity', $(this).parent().prev().find('input').val());
		// 	}
		// });
		// $(".single-product-wrapper .section-product .section-quantity .quantity-arrows .arrow-down").on('click', function() {
		// 	if ($(this).parent().prev().hasClass('quantity')) {
		// 		$(this).parent().prev().find('input')[0].stepDown(1);
		// 		$(".single-product-wrapper .section-product .product-cart").attr('data-product-quantity', $(this).parent().prev().find('input').val());
		// 	}
		// });
		// $(".single-product-wrapper .section-product .section-quantity .quantity input").on('change', function() {
		// 	$(".single-product-wrapper .section-product .product-cart").attr('data-product-quantity', $(this).val());
		// });
	}
});
