/* Recipe Banner Template JS File */
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';

$(document).ready(function() {
	if ($(".contact-recipe-template").length) {
		const bannerContactController = new ScrollMagic.Controller();
		const bannerContactScene = new ScrollMagic.Scene({
			triggerHook: "onEnter",
			duration: "200%"
		})
		.setTween(".contact-banner-template .slide-background .background", {
			y: "30%"
		});
		bannerContactScene.addTo(bannerContactController);
	}
});

