/* Recipe Grid Block JS File */
import select2 from 'select2';
$(document).ready(function() {
    if ($(".recipe-grid-block").length) {

        const baseUrl = location.protocol + '//' + location.hostname + "/" + $(".recipe-grid-wrapper").attr('data-base') + "/";

        $(".filter-value").select2();
        if ($('.recipe-grid-wrapper .post-wrapper .section-body').length) {
            if ($(window).width() >= 768) {
                matchHeight('.recipe-grid-wrapper .post-wrapper .content-wrapper');
            }
        }

        function recipeGridFormData() {
            let formData = {};
            $.each($('#recipe-grid-form').serializeArray(), function() {
                formData[this.name] = this.value;
            });
            return formData;
        }

        function recipeGridCreateUrl(paged) {
            let formData = recipeGridFormData();
            const category = typeof formData.category !== "undefined" && formData.category !== 'all' ? formData.category + '/' : '';
            const year = typeof formData.year !== "undefined" && formData.year !== 'all' ? formData.year + '/' : '';
            const monthnum = typeof formData.monthnum !== "undefined" && formData.monthnum !== 'all' ? formData.monthnum + '/' : '';
            const page = typeof paged !== "undefined" ? 'page/' + paged : '';
            return baseUrl + category + year + monthnum + page;
        }

        function recipeGridPushUrl(paged) {
            if (window.history.replaceState) {
                let formData = recipeGridFormData();
                window.history.pushState({
                    formData: formData,
                    paged: paged
                }, null, recipeGridCreateUrl(paged));
            }
        }

        function recipeGridLoad(paged) {
            recipeGridPushUrl(paged);
            $("#recipe-grid-loader").addClass('active');
            $("#recipe-grid-load").load(coreData.ajaxurl, {
                action: 'load_recipe_grid',
                formData: recipeGridFormData(),
                recipeGridData: $("#recipe-grid-load").attr('data-pgd'),
                paged: paged
            }, function(response) {
                $("#recipe-grid-loader").removeClass('active');
                lazyLoadInstance.update();
                $(window).trigger('resize');
                if ($('.recipe-grid-wrapper .post-wrapper .section-body').length) {
                    matchHeight('.recipe-grid-wrapper .post-wrapper .content-wrapper');
                }
            });
        }

        $("#recipe-grid-form .filter-value").on('select2:select', function() {
            recipeGridLoad();
        });

        $("#recipe-grid-load").on('click', "#pagination .paginate-trigger", function() {
            const paged = $(this).attr('data-paged');
            $(".recipe-grid-wrapper").attr('data-page', paged);
            recipeGridLoad(paged);
            $("body, html").animate({
                scrollTop: $('.section-filter').offset().top - 200
            }, 750);
        });
    }
});
