import mhead from 'mhead-js';
$(window).on('load', function() {
	const mobileHeader = $('.mobile-header');
	const banner = $("#banner-wrapper");
	mobileHeader.mhead({
		scroll: {
			tolerance: 1
		}
	});
});
