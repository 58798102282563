/* From our blog Block JS File */

$(window).on('load rotate resize', function() {
	if ($('.from-our-blog-wrapper').length > 0) {
		matchHeight('.from-our-blog-wrapper .section-blog');
	}
});

$(document).ready(function() {
	if ($('.from-our-blog-wrapper').length > 0) {
		$(document).on('mouseover', '.from-our-blog-wrapper .section-blog', function() {
			$('.from-our-blog-wrapper .section-blog').removeClass('active');
			$(this).addClass('active');
			const id = $(this).attr('data-id');
			$(".from-our-blog-wrapper .blog-image").removeClass('active');
			$(".from-our-blog-wrapper .blog-image[data-id='" + id + "']").addClass('active');
		});
	}
});
