/* Video Grid Block JS File */
import select2 from 'select2';
$(document).ready(function() {
	if ($(".video-grid-block").length) {

		const baseUrl = location.protocol + '//' + location.hostname + "/" + $(".video-grid-wrapper").attr('data-base') + "/";

		$(".filter-value").select2();
		// if ($('.video-grid-wrapper .video-wrapper .section-body').length) {
		// 	if ($(window).width() >= 768) {
		// 		matchHeight('.video-grid-wrapper .video-wrapper .content-wrapper');
		// 	}
		// }

		function videoGridFormData() {
			let formData = {};
			$.each($('#video-grid-form').serializeArray(), function() {
				formData[this.name] = this.value;
			});
			return formData;
		}

		function videoGridCreateUrl(paged) {
			let formData = videoGridFormData();
			const category = typeof formData.category !== "undefined" && formData.category !== 'all' ? formData.category + '/' : '';
			const year = typeof formData.year !== "undefined" && formData.year !== 'all' ? formData.year + '/' : '';
			const monthnum = typeof formData.monthnum !== "undefined" && formData.monthnum !== 'all' ? formData.monthnum + '/' : '';
			const page = typeof paged !== "undefined" ? 'page/' + paged : '';
			return baseUrl + category + year + monthnum + page;
		}

		function videoGridPushUrl(paged) {
			if (window.history.replaceState) {
				let formData = videoGridFormData();
				window.history.pushState({
					formData: formData,
					paged: paged
				}, null, videoGridCreateUrl(paged));
			}
		}

		function videoGridLoad(paged) {
			// videoGridPushUrl(paged);
			$("#video-grid-loader").addClass('active');
			$("#video-grid-load").load(coreData.ajaxurl, {
				action: 'load_video_grid',
				formData: videoGridFormData(),
				videoGridData: $("#video-grid-load").attr('data-pgd'),
				paged: paged
			}, function(response) {
				$("#video-grid-loader").removeClass('active');
				lazyLoadInstance.update();
				$(window).trigger('resize');
			});
		}

		$("#video-grid-form .filter-value").on('select2:select', function() {
			videoGridLoad();
		});

		$("#video-grid-load").on('click', "#pagination .paginate-trigger", function() {
			const paged = $(this).attr('data-paged');
			$(".video-grid-wrapper").attr('data-page', paged);
			videoGridLoad(paged);
			$("body, html").animate({
				scrollTop: $('.section-filter').offset().top - 200
			}, 750);
		});

	}
});
