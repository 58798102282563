/* Bar Prices Block JS File */
$(document).ready(function() {
	if ($('.bar-prices-wrapper').length) {
		matchBarHeight('#bar-prices-block-1 .bar-prices-wrapper .section-bars .col-custom');
		matchBarHeight('#bar-prices-block-2 .bar-prices-wrapper .section-bars .col-custom');
	}
});

window.matchBarHeight = function(element) {
	$(window).on('load rotate resize', function() {
		if ($(window).width() < 768) {
			$(element).height('auto');
		} else if ($(window).width() > 767) {
			let maxHeight = 0;
			let match;
			match = $(element);
			match.height('auto');
			match.each(function() {
				if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
			});
			match.height(maxHeight);
		}
	});
}
