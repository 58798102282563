import slick from 'slick-carousel';

$(document).ready(function() {
    const guideCount = $(".guide-carousel-wrapper .guide-item").length;
    if (guideCount > 1) {
        const guideSliderSlick = $(".guide-carousel-wrapper").on('init', function() {
            $(".guide-carousel-wrapper").fadeIn();
        }).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            focusOnSelect: true,
            autoplay: false,
            autoPlaySpeed: 1000,
            speed: 1000,
            mobileFirst: true,
            adaptiveHeight: true,
            arrows: false,
            dots: true,
            infinite: true,
            fade: true,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        fade: false,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        dots: false,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        fade: false,
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        dots: false,
                    }
                },
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        dots: false,
                        fade: false,
                    }
                }
            ]
        }).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            $('.slick-slide', $(this)).removeClass('slick-fix');
            $('.slick-active.slick-cloned', $(this)).addClass('slick-fix');
            lazyLoadInstance.update();
        });
    } else {
        $('.guide-carousel-wrapper').hide();
    }
});
