/* Blog Banner Template JS File */
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';

$(document).ready(function() {
	if ($(".blog-banner-template").length) {
		const bannerBlogController = new ScrollMagic.Controller();
		const bannerBlogScene = new ScrollMagic.Scene({
			triggerHook: "onEnter",
			duration: "200%"
		})
		.setTween(".blog-banner-template .slide-background .background", {
			y: "30%"
		});
		bannerBlogScene.addTo(bannerBlogController);
	}
});

