/* Landing Content And Video Block JS File */
$(document).ready(function() {
	if ($('.landing-content-and-video-wrapper .section-video .video-popup-wrapper').length > 0) {
        $(document).on('click', '.landing-content-and-video-wrapper .section-video .video-popup-wrapper', function() {
            const source = $(this).attr('data-video-src');
            const video = $(this).attr('data-video-id');

            $.magnificPopup.close();
			$.magnificPopup.open({
				type: 'ajax',
				items: {},
				mainClass: 'video-popup fade-in',
				tLoading: coreData.loader,
				ajax: {
					settings: {
						type: "post",
						url: coreData.ajaxurl,
						data: {
							action: "landing_video_popup",
							source: source,
							video: video,
						},
					},
					cursor: 'mfp-ajax-cur',
					tError: '<a href="%url%">The content</a> could not be loaded.'
				},
				callbacks: {
					ajaxContentAdded: function() {

					}
				}
			});
        });
    }
});
