/* Banner Video Component JS File */
import slick from 'slick-carousel';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';

$(document).ready(function() {
	if ($(".banner-video-wrapper").length) {

		$(".banner-video-slick").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			speed: 2000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			arrows: false,
			dots: true,
			appendDots: '.banner-slide-navigation',
			fade: true,
			rows: 0,
			adaptiveHeight: true,
			customPaging: function(slider, i) {
				return '<button>' + ('0' + (i + 1)).slice(-2) + '</button>';
			}
		});
	}
});

