/* Recipes Block JS File */
import slick from 'slick-carousel';

$(window).on('rotate resize', function() {
	if ($('.recipes-wrapper').length > 0) {
		setTimeout(function() {
			matchHeight('.recipes-wrapper .section-recipe .section-title');
			matchHeight('.recipes-wrapper .section-recipe .section-content');
		}, 100);
	}
});

$(document).ready(function() {
	if ($('.recipes-wrapper').length > 0) {
		$(".slick-recipes").on('init', function() {
			matchHeight('.recipes-wrapper .section-recipe .section-title');
			matchHeight('.recipes-wrapper .section-recipe .section-content');
		}).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			speed: 2000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			arrows: true,
			dots: false,
			appendArrows: '.recipe-arrows',
			prevArrow: '<i class="fas fa-arrow-circle-left"></i>',
			nextArrow: '<i class="fas fa-arrow-circle-right"></i>',
			fade: true,
			rows: 0,
			adaptiveHeight: true,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						fade: false
					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						fade: false
					}
				},
				{
					breakpoint: 1199,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1,
						fade: false
					}
				}
			]
		});
	}
});
