/* Recipe Banner Template JS File */
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';

$(document).ready(function() {
	if ($(".recipe-recipe-template").length) {
		const bannerRecipeController = new ScrollMagic.Controller();
		const bannerRecipeScene = new ScrollMagic.Scene({
			triggerHook: "onEnter",
			duration: "200%"
		})
		.setTween(".recipe-banner-template .slide-background .background", {
			y: "30%"
		});
		bannerRecipeScene.addTo(bannerRecipeController);
	}
});

