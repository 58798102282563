import mhead from 'mhead-js';
$(window).on('load', function() {
	const desktopHeader = $('.desktop-header');
	const banner = $("#banner-wrapper");
	desktopHeader.mhead({
		scroll: {
			tolerance: 1,
			hide: 50,
			show: 50
		}
	});
});
