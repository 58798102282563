/* Banner Home Component JS File */
import slick from 'slick-carousel';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';

$(document).ready(function() {
	if ($(".banner-home-wrapper").length) {
		if ($(".banner-home-wrapper .banner-home-slick").length && $(".banner-home-slick .banner-slide").length > 1) {
			$(".banner-home-slick").slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 2000,
				speed: 2000,
				mobileFirst: true,
				pauseOnDotsHover: false,
				pauseOnFocus: false,
				pauseOnHover: true,
				arrows: false,
				dots: true,
				fade: true
			});
		}

		const bannerHomeController = new ScrollMagic.Controller();
		const bannerHomeScene = new ScrollMagic.Scene({
			triggerHook: "onEnter",
			duration: "200%"
		})
		.setTween(".banner-home-wrapper .slide-wrapper:not(.left-side):not(.cta-banner) .section-background .background", {
			y: "30%"
		});

		bannerHomeScene.addTo(bannerHomeController);


		// const bodyWrapperController = new ScrollMagic.Controller();
		// const bodyWrapperScene = new ScrollMagic.Scene({
		// 	triggerHook: "onEnter",
		// 	duration: "200%"
		// })
		// .setTween(".banner-home-wrapper .body-wrapper", {
		// 	y: "30%"
		// });
		// bodyWrapperScene.addTo(bodyWrapperController);
	}
});

