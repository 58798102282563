/* BMI Calculator Block JS File */
$(document).ready(function() {

	if ($(".bmi-calculator-wrapper").length > 0) {

		function calcBMI(weight, height) {
			if (weight > 0 && height > 0) {
				const bmi = weight / (height / 100 * height / 100);
				if (bmi) {
					$(".bmi-calculator-wrapper #bmi-result").val(bmi.toFixed(2));
				}
			}
		}

		$(document).on("change paste keyup", ".bmi-calculator-wrapper #bmi-weight", function() {
			const weight = $(this).val();
			const height = $(".bmi-calculator-wrapper #bmi-height").val();
			calcBMI(weight, height);
		});

		$(document).on("change paste keyup", ".bmi-calculator-wrapper #bmi-height", function() {
			const height = $(this).val();
			const weight = $(".bmi-calculator-wrapper #bmi-weight").val();
			calcBMI(weight, height);
		});
	}
});
