/* Consult CTA Large Block JS File */
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
$(document).ready(function() {
	if ($('.consult-cta-large-wrapper').length > 0) {
		const consultBackgroundTween = TweenMax.fromTo(".consult-cta-large-block .section-background .background", 1, { y: '-25%' }, { y: '20%' });
		const consultBackgroundController = new ScrollMagic.Controller();
		const consultBackgroundScene = new ScrollMagic.Scene({
			triggerElement: ".consult-cta-large-block .section-background .background",
			triggerHook: "onEnter",
			duration: "400%"
		}).setTween(consultBackgroundTween);
		consultBackgroundScene.addTo(consultBackgroundController);
	}
});
