/* Banner Content Component JS File */
import slick from 'slick-carousel';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';
import Cookies from 'js-cookie';

$(document).ready(function() {
	if ($(".banner-content-wrapper").length) {

		$(".banner-content-slick").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			speed: 2000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			arrows: false,
			dots: true,
			appendDots: '.banner-slide-navigation',
			fade: true,
			rows: 0,
			adaptiveHeight: true,
			customPaging: function(slider, i) {
				return '<button>' + ('0' + (i + 1)).slice(-2) + '</button>';
			}
		});

		const bannerContentController = new ScrollMagic.Controller();
		const bannerContentScene = new ScrollMagic.Scene({
			triggerHook: "onEnter",
			duration: "200%"
		})
		.setTween(".banner-content-wrapper .banner-background .background", {
			y: "30%"
		});
		if ($(window).width() >= 1200) {
			bannerContentScene.addTo(bannerContentController);
		}


		if ($(".banner-content-wrapper .section-calculator .calculator-form").length > 0) {
			$(document).on('click', '#bmi-slider-form-block #bmi-calculator-slider-submit', function(e) {
				e.preventDefault();
				const height = $(".height-field #height-input").val();
				const weight = $(".weight-field #weight-input").val();
				const result = $(".results-field #bmi-results").val();
				const meaning = $(".meaning-field #bmi-meaning").val();
				Cookies.set('bmi_weight', weight);
				Cookies.set('bmi_height', height);
				Cookies.set('bmi_result', result);
				Cookies.set('bmi_meaning', meaning);
				$("#bmi-slider-form-block").submit();
			});
		}


		if ($(".banner-content-wrapper .section-video").length > 0) {

			$(document).on('click', '.banner-content-wrapper .section-video .video-popup-wrapper', function() {
				const source = $(this).attr('data-video-src');
				const video = $(this).attr('data-video-id');
				$.magnificPopup.close();
				$.magnificPopup.open({
					type: 'ajax',
					items: {},
					mainClass: 'video-popup fade-in',
					tLoading: coreData.loader,
					ajax: {
						settings: {
							type: "post",
							url: coreData.ajaxurl,
							data: {
								action: "banner_content_video_popup",
								source: source,
								video: video,
							},
						},
						cursor: 'mfp-ajax-cur',
						tError: '<a href="%url%">The content</a> could not be loaded.'
					},
					callbacks: {
						ajaxContentAdded: function() {

						}
					}
				});
			});

		}

	}
});

